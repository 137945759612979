<template>
  <el-dialog
    title="批量导入"
    :visible.sync="dialogType"
    v-if="dialogType"
    center
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form style="padding: 20px 30px">
      <p style="margin-bottom: 10px">请选择需要上传的Excel文件：</p>
      <el-upload
        class="upload-demo"
        :action="UploadUrl()"
        :on-change="fileChange"
        :on-exceed="exceedFile"
        :on-success="handleSuccess"
        :on-error="handleError"
        :before-upload="beforeUploadFile"
        :on-remove="handleRemove"
        :file-list="fileList"
        :limit="limitNum"
        accept=".xlsx"
        :auto-upload="false"
      >
        <el-button
          size="small"
          type="primary"
          style="margin-right: 5px; background-color: #5C307D; border: #5C307D"
          >点击选择文件</el-button
        >
        <span
          style="line-height: 30px; height: 30px;"
          slot="tip"
          class="el-upload__tip"
        >
          或<el-link
            type="success"
            style="margin: auto 5px; padding-bottom: 2px"
            >下载模板</el-link
          >，并按照模板编辑导入的数据。
        </span>
      </el-upload>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()" size="small">取 消</el-button>
      <el-button
        type="primary"
        size="small"
        style="background-color: #5C307D; border: #5C307D"
        @click="uploadFile"
        :disabled="isDisable"
        >上 传</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { importExcelStudent } from "r/admin/examinee";
import { importExcelTeacher } from "r/admin/staff";
import { Loading } from "element-ui";
export default {
  name: "ImportExcelDialog",
  props: {
    dialogVisible: {
      type: Boolean,
    },
    message: {
      type: String,
    },
    excelType: {
      type: String,
    },
    examId: {
      //考试ID
      type: String,
    },
  },
  data() {
    return {
      dialogType: this.dialogVisible, //弹框状态
      limitNum: 1, // 上传excel时，同时允许上传的最大数
      fileList: [], // excel文件列表
      isDisable: false, //提交按钮是否可点击（防重复点击）
      e_type: this.excelType, //上传的Excel：1（学生），2（监控人员）
    };
  },
  methods: {
    // 右上角X,取消关闭弹框
    handleClose() {
      this.dialogType = false;
      this.fileList = [];
      this.$emit("closeDialog", this.dialogType);
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.warning(
        `只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length +
          fileList.length} 个`
      );
    },
    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      this.fileList.push(file.raw);
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUploadFile(file) {
      console.log("before upload");
      console.log(file);
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      let size = file.size / 1024 / 1024;
      //这里不用限制文件格式
      // if (extension !== "xlsx") {
      //   this.$message.warning("只能上传后缀是.xlsx的文件");
      // }
      if (size > 10) {
        this.$message.warning("文件大小不得超过10M");
      }
    },
    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      this.$message.success("文件上传成功");
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      this.$message.error("文件上传失败");
    },
    handleRemove(file, fileList) {
      this.fileList = [];
    },
    UploadUrl: function() {
      // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
      return "";
    },
    // 点击确认上传
    uploadFile() {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      if (this.fileList.length === 0) {
        this.$message.warning("请上传文件");
      } else {
        let form = new FormData();
        form.append("file", this.fileList[0]);
        // 导入学生
        if (this.e_type == "1") {
          this.importExcelStu({ file: form, examId: this.examId });
        }
        // 导入监控人员
        if (this.e_type == "2") {
          this.importExcelTeacher({ file: form, examId: this.examId });
        }
      }
    },
    //导入学生信息表
    async importExcelStu(data) {
      this.openFullScreen();
      const { data: res } = await importExcelStudent(data);
      console.log(res,"学生信息表");
      if (res.code == 500) {
        this.closeFullScreen(this.openFullScreen());
            this.$message({ 
              showClose: true,
              message: res.message,
              type: 'error',
              duration: 86400000
            });
            return
       
      }
      this.closeFullScreen(this.openFullScreen());
      this.$message.success(res.message);
    },

    //导入监控人员信息表
    async importExcelTeacher(data) {
      this.openFullScreen();
      const { data: res } = await importExcelTeacher(data);
      console.log(res,"监控人员")
      if (res.code !== 200) {
        this.closeFullScreen(this.openFullScreen());
        //导入失败之后弹出提示框
          this.$message({ 
              showClose: true,
              message: res.message,
              type: 'error',
              duration: 86400000
            });
            return
      }
      this.closeFullScreen(this.openFullScreen());
      this.$message.success(res.message);
    },
    //Loading加载
    openFullScreen() {
      const loading = this.$loading({
        lock: true,
        text: "上传中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      return loading;
    },
    //关闭loading
    closeFullScreen(loading) {
      loading.close();
      // 关闭弹框
      this.handleClose();
    },
  },
  watch: {
    dialogVisible() {
      this.dialogType = this.dialogVisible;
    },
    excelType() {
      this.e_type = this.excelType;
    },
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/dialog";
</style>
