import { request } from "./network";
// 查看上传图片
export function getStudentUrl(params) {
    return request({
        method: "get",
        url: "/student/getStudentUrl",
        params,
    });
}

export function getAdminStudentUrl(params) {
    return request({
        method: "get",
        url: "/student/getStudentUrlByExamId",
        params,
    });
}
// 获取考试数据列表接口
export function getExamData(params) {
    return request({
        method: "get",
        url: "/exam/lists",
        params,
    });
}

// 获取所有考生
export function getStuData(params) {
    return request({
        method: "get",
        url: "student/listAll",
        params,
    });
}
// 分页展示考生列表
export function getStuPageData(params) {
    return request({
        method: "get",
        url: "student/listPage",
        params,
    });
}
// 判断考生是否关联监控组
export function studentIsGroup(params) {
    return request({
        method: "get",
        url: "/group/student/isGroup",
        params,
    });
}

// 删除单个考生
export function deleteStuById(data) {
    return request({
        method: "get",
        url: "student/delete/"+data.id+"?examId="+data.examId,
        data,
    });
}

// 批量删除
export function deleteStudents(params) {
    return request({
        method: "post",
        url: "student/delete/batch",
        params,
    });
}

// 获取考试科目
export function getSubAllByExamId(data) {
    return request({
        method: "post",
        url: "subject/getAllForExamId?examId="+data,
        data,
    });
}

// 获取省
export function getAllProvince(params) {
    return request({
        method: "get",
        url: "/school/listAllProvince",
        params,
    });
}

// 获取城市
export function getCityByPro(params) {
    return request({
        method: "get",
        url: "/school/listAllCity/"+params,
        params,
    });
}

// 根据省、市获得学校
export function getSchoolList(params) {
    return request({
        method: "get",
        url: "/school/findSchool",
        params,
    });
}

// 单个新增学生
export function createStu(data) {
    return request({
        method: "post",
        url: "/student/create",
        data,
    });
}

// 修改学生信息
export function updateStuById(data) {
    return request({
        method: "post",
        url: "/student/update/"+data.id,
        data,
    });
}

// 批量导入
export function importExcelStudent(data) {
    return request({
        method: "post",
        url: "/student/importExcelStudent?examId="+data.examId,
        data: data.file,
    });
}

// 按准考证查找学生
export function getStuByZkzNum(params) {
    return request({
        method: "get",
        url: "/student/find/"+params.zkzNum,
        params,
    });
}